import React from "react";
import "../../src/styles/global.css";
import { FrodeISverm, HonningGlass, LindsethOgFar, Logo, Salgsbod, StillBilde } from "../assets/Assets";
import { Head } from "../components/Components";

export default function Home() {
  return (
    <div>
      <div class="container">
        <Head></Head>

        <div class="header">
          <img src={Logo} alt="logo" />
          <h1>Lindseth og far - honning andre biprodukter</h1>
        </div>

        <img
          class="main-image"
          src={LindsethOgFar}
          alt="Bilde av Lindseth og Far. Fredrik til venstre og Frode til høyre"
          title="Bilde av Lindseth og Far. Fredrik til venstre og Frode til høyre"
        />
        <div id="om-oss" class="content-row">
          <div>
            <h2>Om oss</h2>
            <p>
              Lindseth og far er Fredrik og Frode Lindseth. Fredrik startet med bier og etter bare en liten stund ble
              Frode med, og siden har de drevet sammen
            </p>
            <p>
              Vi har bier fast på Knappskog, Sotra og på Leirnes, Høyanger. På forsommeren er biene i Hardanger og
              pollinerer epler og moreller på Nå i Hardanger, mens på høsten er biene på Askrova og lager honning av
              lyngen.
            </p>
            <p>
              Vi selger honning kontinuerlig gjennom året, så det er bare å ta kontakt eller kjøpe på et av
              utsalgsstedene
            </p>
          </div>
        </div>

        <video
          poster={StillBilde}
          width="520"
          height="340"
          controls
          loop
          track="../assets/undertekst.vtt"
          alt="Video av Frode som inspiserer en bikube"
          title="Video av Frode som inspiserer en bikube"
        >
          <source src={FrodeISverm} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>

        <div class="content-row">
          <div>
            <h2>Utsalgssteder</h2>
            <p>
              Vi har utsalg på hos Frode på Knappskog, salgsbod langs veien på Leirnes, butikken i Bjordal og hos
              Fredrik i Lægdesvingen.
            </p>
          </div>

          <div>
            <h2>Utkjøring</h2>
            <p>
              Siden tingene er som det er og det er vanskelig eller uønsket å kjøpe på butikker eller markeder så tilbyr
              vi også utkjøing! Hver onsdag kjører vi ut der du er. Bare ta kontakt så ordner vi det
            </p>
          </div>
        </div>

        <img
          class="side-image"
          src={Salgsbod}
          alt="Bilde av salgsboden langs veien på Leirnes"
          title="Bilde av salgsboden langs veien på Leirnes"
        />

        <div id="priser">
          <h2>Priser</h2>
          <div>
            <p>Vi selger både og lynghonning og sommerhonning og prisene for de er det samme.</p>
            <p>
              Vi selger også hele honningrammer med ubehandlet honning rett fra kubene, pollen i perioder, avleggere fra
              bikubene, voksark til mat og tidvis annet laget med produkter fra biene
            </p>
          </div>

          <table class="prices" id="kr">
            <thead>
              <tr>
                <th>Glasstørrelse</th>
                <th>Pris</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
              <td>230 gram</td>
              <td>90 kr</td>
            </tr> */}
              <tr>
                <td>250 gram</td>
                <td>95 kr</td>
              </tr>
              <tr>
                <td>450 gram</td>
                <td>165 kr</td>
              </tr>
              {/* <tr>
                <td>500 gram</td>
                <td>180 kr</td>
              </tr> */}
              <tr>
                <td>1000 gram</td>
                <td>
                  360 kr
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <img
          class="side-image"
          src={HonningGlass}
          alt="Bilde honning på spruteflaske, kiloglass og halvkiloglass"
          title="Bilde honning på spruteflaske, kiloglass og halvkiloglass"
        />

        <div id="kontakt" class="footer">
          <div>
            <h2>Kontakt oss</h2>
            <p>
              Du kan kontakte oss begge ved å sende en epost til{" "}
              <a href="mailto:hei@lindsethogfar.no">hei@lindsethogfar.no</a>
            </p>
            <p>
              Frode Lindseth, Sveane, 5363 Ågotnes, tlf: <a href="tel:+4795409288">95409288</a> eller
              <a href="https://fredriklindseth.no">Fredrik Lindseth</a> , Lægdesvingen, 5096 Bergen, tlf :{" "}
              <a href="tel:+4795853805">95853805</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
