import React from "react";
import { Helmet } from "react-helmet";

export default function Head() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lindseth og far</title>
        <link rel="canonical" href="https://lindsethogfar.no" />
      </Helmet>
    </div>
  );
}
